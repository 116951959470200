import * as React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import ReactAudioPlayer from 'react-audio-player';
import { useStaticQuery, graphql } from "gatsby"

const AudioPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        audioSection {
          fileUpload {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)
  const audio = data.contentfulResourcePage.audioSection.fileUpload;
  return (
    <Layout>
      <SEO title="Resources" />
      <div className="sidebar-page-container">
        <div className="services-section">
          <div className="auto-container">
            <div className="sec-title">
              <div className="title">tekki fii</div>
              <h2><span>Resources </span>Audio</h2>
            </div>
            <div className="inner-container">
              <div className="video">
                <div className="tab-content">
                  <div className="row g-3">
                    {audio.map(({ file, title }, index) => (
                      <div className="video-block col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                        <div className="card text-white shadow-sm">
                          <div className="card-body card-content">
                            <h4 className="card-title-header">{title}</h4>
                            <ReactAudioPlayer
                              src={file.url}
                              controls
                              className="audioStyle"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default AudioPage

